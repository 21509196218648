export default {
    "sx": (theme) => ({
        "& .dialogLogo": {
            "textAlign": "center",
            "padding-top": theme.spacing(5),
            [theme.breakpoints.up('sm')]: {
                "padding-top": theme.spacing(8),
            },
            "& svg": {
                "width": "auto",
                "height": "auto",
                "max-height": "40px",
                [theme.breakpoints.up('sm')]: {
                    "max-height": "50px"
                },
                [theme.breakpoints.up('md')]: {
                    "max-height": "80px"
                },
                "& path": {
                    "fill": theme?.palette?.icon?.loginLogo || ''
                }
            }
        },
    })
}