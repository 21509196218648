/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/
//import Typography from "@mui/material/Typography";
//import Grid from "@material-ui/core/Grid";

import { useTheme } from "../../core/StarberryComponentsMui"
import {
    MYACCOUNT_AUTHCALLBACK
} from "../../core/constants/urls";

import SignUpSocial from "../../core/components/MyAccount/modals/SignUpSocial"
// import AuthCallback from "../../core/components/MyAccount/user/authcallback"
import PageHeaderHelmet from "../../core/components/pageHeader";

const MyAccountPages = () => {

    const theme = useTheme()

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                {/* <AuthCallback path={MYACCOUNT_AUTHCALLBACK} theme={theme} /> */}
                <SignUpSocial path={MYACCOUNT_AUTHCALLBACK} theme={theme} />
            </Router>
        </>
    )
}

export default MyAccountPages
