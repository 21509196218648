import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

const StyledDialogTitle = ({ id, title, dialogTitleProps }) => {

    return(
        <DialogTitle
            id={id}
            component="div"
            classes={{root: "dialogTitle"}}
        >
            <Typography {...dialogTitleProps}>
                {title}
            </Typography>
        </DialogTitle>
    )
};

export default StyledDialogTitle;
