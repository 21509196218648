import React from 'react';
import { isEmpty } from 'lodash';
import { trackEvents } from "../helper";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import DialogActions from '@mui/material/DialogActions';
import FormPhoneNumber from "../forms/PhoneNumberField"
import { MYACCOUNT_LIGHT } from "../../../constants/urls";
import { updateProfile } from "../../../services/store/api"
import { loginRedirect } from '../user/authcallback'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

import Alert from '../alert';

const TermsText = ({ dialogTermsProps, termsText, termsLink, termsLabel, privacyLink, privacyLabel, termsLinkProps }) => {
    return(
        <Typography classes={{root: "dialogTerms"}} {...dialogTermsProps}>
        </Typography>
    )
}

const StyledDialogContent = (props) => {
    const {
        // handleClose,
        // subtitle,
        // showSocialLogin,
        showMarketingConsent,
        showTerms,
        termsText,
        termsLinkProps,
        termsLink,
        termsLabel,
        privacyLink,
        privacyLabel,
        state,
        services,
        formActionLabel,
        dialogContentProps,
        defaultCountry,
        usePhoneField
    } = props

    const {
        dialogSeparatorProps,
        dialogSeparatorSpanProps,
        dialogFormControlProps,
        dialogTermsProps,
        // dialogHelperTextProps,
        dialogActionsBtnProps,

    } = dialogContentProps

    const [values, setValues] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (state?.user && (!values.email)) {
            setValues({
                forename: state?.user?.forename || state?.user?.name || '',
                surname: state?.user?.surname || '',
                email: state?.user?.email || '',
                newsAndUpdatesSubscription: state?.user?.newsAndUpdatesSubscription
            });
            setValidation({
                ...validation,
                forename: !(state?.user?.forename || state?.user?.name || ''),
                surname: !(state?.user?.surname || ''),
                email: !(state?.user?.email || '')
            })
        }
    }, state.user)

    const [forenameErroMsg, setForenameErroMsg] = React.useState('');
    const [surnameErroMsg, setSurnameErroMsg] = React.useState('');
    const [validation, setValidation] = React.useState({
        forename: false,
        surname: false,
        email: false,
        // mobile: false
    });

    const handleChange = (prop) => (event) => {
        if (!event.target.value) {
            setValidation({...validation, [prop]:true})
        } else {
            if (!event.target.name === 'newsAndUpdatesSubscription')
                setValidation({...validation, [prop]:false})
        }

        let newVal = event.target.value;

        if (event.target.name === 'newsAndUpdatesSubscription') {
            newVal = (event.target.value === '1')
        }

        setValues({ ...values, [prop]: newVal });

        if(prop === 'forename'){
            if(event.target.value?.length > 11){
                setForenameErroMsg("Please enter 12 characters only")
                setValidation({...validation, [prop]:true})
            }else{
                setForenameErroMsg("")
            }
        }
        if(prop === 'surname'){
            if(event.target.value?.length > 29){
                setSurnameErroMsg("Please enter 30 characters only")
                setValidation({...validation, [prop]:true})
            }else{
                setSurnameErroMsg("")
            }
        }
    };
    const handlePhoneNumber = (value) => {
        setValues({ ...values, mobile: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let hasError = false;
        // if(values.forename.length < 12 && values.surname.length < 30){
            if (isEmpty(values)) {
                setValidation({
                    forename: false,
                    surname: false,
                    email: true,
                    // password: true
                })
            } else {
                let validationAlt = {}
                let errotStatus;
                Object.entries(validation).map(([key, value]) => {
                    if (!values[key]) {
                        errotStatus = true;
                        hasError = true;
                    } else {
                        errotStatus = false;
                    }
                    validationAlt[key] = errotStatus;
                })

                if (usePhoneField) {
                    if (!values['mobile']) {
                        errotStatus = true;
                        hasError = true;
                    } else {
                        errotStatus = false;
                    }
                    validationAlt['mobile'] = errotStatus;
                }

                setValidation(validationAlt)
            }

            if (!hasError) {
                setLoading(true)
                updateProfile({...values, id:state?.user?.uid || state?.user?.id});
                loginRedirect(services, state)
            } else {
                services.updateState({message: '', error: false});
            }
        // }
    };

    return(
        <DialogContent classes={{root: "dialogContent"}}>
            <Typography
                {...dialogSeparatorProps}
                classes={{root: "dialogSeparatorr"}}
            >
                <Typography classes={{root: "dialogSeparatorSpan"}} {...dialogSeparatorSpanProps}></Typography>
            </Typography>

            <form noValidate autoComplete="off" id="myAccountSignUpForm">
                <Alert type="alert" message={state.message} severity={state.error ? 'error' : 'success'} />
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="signup-forename"
                        type="text"
                        label="First name"
                        onChange={handleChange('forename')}
                        classes={{root: "dialogFormControlElem"}}
                        required={true}
                        error={validation['forename']}
                        helperText={forenameErroMsg}
                        defaultValue={values.forename}
                        value={values.forename}
                    />
                </FormControl>
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="signup-surname"
                        type="text"
                        label="Surname"
                        onChange={handleChange('surname')}
                        classes={{root: "dialogFormControlElem"}}
                        required={true}
                        error={validation['surname']}
                        helperText={surnameErroMsg}
                        defaultValue={values.surname}
                        value={values.surname}
                    />
                </FormControl>
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="signup-email"
                        type="text"
                        label="Email Address"
                        onChange={handleChange('email')}
                        classes={{root: "dialogFormControlElem"}}
                        required={true}
                        error={validation['email']}
                        defaultValue={values.email}
                        value={values.email}
                        disabled={true}
                    />
                </FormControl>
                {usePhoneField && (
                    <FormControl {...dialogFormControlProps}>
                        <FormPhoneNumber
                            type="tel"
                            name="mobile"
                            label="Mobile"
                            id="mobile"
                            required={true}
                            key={'mobile'}
                            classes={{root: "globalForm-selectField"}}
                            // defaultValue={defaultValue}
                            handleOnChange={handlePhoneNumber}
                            defaultCountry={defaultCountry}
                        />
                    </FormControl>
                )}

                {(!MYACCOUNT_LIGHT && showMarketingConsent) && (
                    <>
                    <FormControl {...dialogFormControlProps}>
                        <FormLabel id="demo-radio-buttons-group-label">Agree to receive some interesting news and updates</FormLabel>
                        <RadioGroup
                            defaultValue={(values.newsAndUpdatesSubscription) ? '1' : '0'}
                            row
                            value={(values.newsAndUpdatesSubscription) ? '1' : '0'}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="newsAndUpdatesSubscription"
                            onChange={handleChange('newsAndUpdatesSubscription')}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                            <FormControlLabel value="0" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    
                    {/* <FormControl {...dialogFormControlProps}>
                        <FormControlLabel
                            classes={{root: "dialogCheckbox"}}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={values.newsAndUpdatesSubscription}
                                    className={'dialogCheckbox'}
                                    name="newsAndUpdatesSubscription"
                                    onChange={handleChange('newsAndUpdatesSubscription')}
                                />
                            }
                            label="Agree to receive some interesting news and updates"
                        />
                    </FormControl> */}
                    </>
                )}

                {showTerms && termsText &&
                    <TermsText
                        dialogTermsProps={dialogTermsProps}
                        termsText={termsText}
                        termsLinkProps={termsLinkProps}
                        termsLink={termsLink}
                        termsLabel={termsLabel}
                        privacyLink={privacyLink}
                        privacyLabel={privacyLabel}
                    />
                }

                <DialogActions classes={{root: "dialogActions"}}>
                    <Button type="submit" disabled={loading || false} onClick={handleSubmit} {...dialogActionsBtnProps} classes={{root: "dialogActionsBtn"}} id="myAccountSignupSubmit">
                        {formActionLabel}
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    )
};

export default StyledDialogContent;
